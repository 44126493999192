import { useState, useEffect } from 'react';

type DivRefTypes = 'ScrollTrigger';

const useGsapLoaded = (plugins: DivRefTypes[] = []) => {
  const [isGsapLoaded, setIsGsapLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Check if GSAP is loaded and all plugins are available in the window object
      const arePluginsLoaded = plugins.every((plugin) => window[plugin as keyof Window]);

      if (window.gsap && arePluginsLoaded) {
        clearInterval(interval);
        setIsGsapLoaded(true);
      }
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [plugins]);

  return isGsapLoaded;
};

export default useGsapLoaded;
