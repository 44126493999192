import React from 'react';
import { CommonPropTypes } from '../IconTypes';

const ArrowSlim: React.FC<CommonPropTypes> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width='11'
      height='7'
      viewBox='0 0 11 7'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8098 3.93999L8.0498 6.7C7.8498 6.9 7.5398 6.9 7.3598 6.7C7.1598 6.52 7.1598 6.21 7.3598 6.01L9.28979 4.08001H1.0498C0.769805 4.08001 0.559814 3.88002 0.559814 3.59002C0.559814 3.33002 0.759805 3.10003 1.0498 3.10003H9.28979L7.3598 1.17003C7.1598 0.990034 7.1598 0.680032 7.3598 0.480032C7.5398 0.300032 7.8498 0.300032 8.0498 0.480032L10.8098 3.26C10.9898 3.44 10.9898 3.74998 10.8098 3.92998V3.93999Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ArrowSlim;
