'use client';
import React from 'react';

import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';

import NextImage from '@src/components/Shared/NextImage';

import { MoveHealthAppPropTypes } from './ExerciseThatWorks.types';

import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';

import InlineSVG from '@src/components/Shared/InlineSVG';
import useTileFadeIn from '@src/hooks/useTileFadeIn';

const ExerciseThatWorks: React.FC<MoveHealthAppPropTypes> = ({ data }) => {
  const { figureRef, addDataTiles } = useTileFadeIn();

  React.useEffect(() => {
    if (figureRef.current) {
      const childElements = figureRef.current.querySelectorAll('.data-tile');
      childElements.forEach((el) => addDataTiles(el as HTMLElement));
    }
  }, [figureRef, addDataTiles]);

  return (
    <Container
      className={`flex flex-col gap-[4.2rem] overflow-y-hidden lg:flex lg:flex-row lg:items-center lg:gap-[6.4rem]`}
    >
      <NextImage
        imgRef={figureRef}
        className={`opacisty-[0] lg:mb-[unset] lg:w-[50%]`}
        src={data?.mediaPrimary?.url}
        alt={data?.mediaPrimary?.description}
        aspectRatio={data?.mediaPrimary?.width / data?.mediaPrimary?.height}
        width={data?.mediaPrimary?.width}
        objectFit='contain'
      >
        <InlineSVG
          src='https://images.ctfassets.net/98s79sqwuajy/6RzMUYavzruV5fsUl0nlRf/507d13e239f0c479bfc66b6c8ac1b63c/total-knee-replacement.svg'
          className='data-tile left-[10%] top-[10%] w-[40%] lg:w-[20rem] 2xl:w-[30rem]'
        />

        <InlineSVG
          src='https://images.ctfassets.net/98s79sqwuajy/1qwSSgXn9iqy1tk3jaK6MQ/f4b5685666d646747db4a0bffe605fc0/repeat-session.svg'
          className='!absolute bottom-[10%] left-[13%] w-[12rem] md:w-[15rem] lg:w-[13.5rem] 3xl:w-[18rem]'
        />

        <span className='absolute bottom-[20%] right-[10%] text-[1.3rem] font-[500] text-body-copy--light sm:text-[1.6rem] md:text-[1.8rem] 2xl:text-[2.4rem]'>
          75% complete
        </span>
      </NextImage>

      <div className={`lg:relative lg:z-[20] lg:w-[50%] lg:self-center`}>
        <SectionHeader
          className={`lg:relative lg:z-[5] lg:py-[4rem]`}
          preTitleClassName='mb-[1.6rem] font-[400]'
          title={data?.title}
          titleClassName={`text-[2.8rem] mb-[1.8rem] lg:mb-[3.2rem] lg:text-[4.8rem] 2xl:text-[6.1rem] lg:leading-[5rem] 2xl:leading-[5.2rem] lg:tracking-[-0.096rem]`}
          childrenClassName='relative'
          align='left'
        >
          <ParagraphSeparator>
            <RenderBodyCopy className='max-w-[47rem]' bodyCopy={data?.bodyCopy?.json?.content} />
          </ParagraphSeparator>
          <RenderButtons items={data?.buttonCollection?.items} />
        </SectionHeader>
      </div>
    </Container>
  );
};

export default ExerciseThatWorks;
