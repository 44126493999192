'use client';

import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';

import styles from './dataTilesTwo.module.css';

import useTileFadeIn from '@src/hooks/useTileFadeIn';

export default function DataTilesTwo({
  attr,
  imageSrc,
  alt,
  sectionClass,
  boxClassName,
  imageClassName,
  objectFit = 'cover',
  priority,
  imgFirstRatio = 16 / 9,
}: any) {
  const { figureRef, addDataTiles } = useTileFadeIn();

  return (
    <Container attr={attr} className={sectionClass}>
      <div className={`${styles.dataTilesWrapper}`}>
        <div className='relative mb-[2rem]'>
          <NextImage
            imgRef={figureRef}
            className={`${imageClassName}`}
            src={imageSrc}
            alt={alt}
            aspectRatio={imgFirstRatio}
            priority={priority}
            width={100}
            objectFit={objectFit}
          ></NextImage>

          <div className={`${styles?.dataTilesModal}`}>
            <div
              ref={(el) => addDataTiles(el)}
              className={`${styles?.modalInput} left-[47%] top-[44%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}
            >
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage
                    src='https://images.ctfassets.net/98s79sqwuajy/2CJwuqE8pZnHPf5aMzORHp/5f41505e7b6334cefe04c5b3af9a560b/dtile2.svg'
                    alt='icon'
                  ></NextImage>
                </div>
              </div>
            </div>
            <div
              ref={(el) => addDataTiles(el)}
              className={`${styles?.modalInput} left-[75%] top-[65%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}
            >
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage
                    src='https://images.ctfassets.net/98s79sqwuajy/7oZSQk4zUkmFPNoHYcXP0f/bd11448fcd31b1bb391de221bf50ac20/dtile1.svg'
                    alt='icon'
                  ></NextImage>
                </div>
              </div>
            </div>
            <div
              ref={(el) => addDataTiles(el)}
              className={`${styles?.modalInput} left-[46%] top-[7%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}
            >
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage
                    src='https://images.ctfassets.net/98s79sqwuajy/oPkmfKjVdrKAQIC9Lib3y/4d72c6c0db459396903e8f18ae37ffc8/dtile3.svg'
                    alt='icon'
                  ></NextImage>
                </div>
              </div>
            </div>
            <div
              ref={(el) => addDataTiles(el)}
              className={`${styles?.modalInput} left-[78%] top-[8%] block w-[25rem] md:grid  md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}
            >
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage
                    src='https://images.ctfassets.net/98s79sqwuajy/6UajM4fShcTUqz8iiSAh1A/7cb0d236c2b9e2899b59d46435122702/dtile4.svg'
                    alt='icon'
                  ></NextImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

// 'use client';

// import Container from '@src/components/Shared/Container';
// import NextImage from '@src/components/Shared/NextImage';
// import InlineSVG from '@src/components/Shared/InlineSVG';

// import useTileFadeIn from '@src/hooks/useTileFadeIn';

// export default function DataTilesTwo({ attr, imageSrc, alt, sectionClass, imageClassName }: any) {
//   const { figureRef, addDataTiles } = useTileFadeIn();

//   return (
//     <Container attr={attr} className={`h-[58rem] lg:h-[auto] ${sectionClass}`}>
//       <NextImage
//         imgRef={figureRef}
//         className={`relative h-[100%] w-[100%] rounded-[1rem] md:h-[58rem] md:px-[0] lg:h-auto ${imageClassName}`}
//         src={imageSrc}
//         alt={alt}
//       >
//         <div className='relative left-[8%] top-[10%] text-[2.8rem] font-[300] leading-[3.6rem] text-[#fff] md:top-[42%] md:text-[3.6rem] md:leading-[4.2rem] lg:text-[2.4rem] lg:leading-[3.2rem] xl:text-[4.8rem] xl:leading-[6rem]'>
//           All of your movement health <br />
//           data in <strong className='font-[500]'>one place.</strong>
//         </div>

//         <div
//           ref={(el) => addDataTiles(el)}
//           className='absolute bottom-[5%] right-[38%] w-[19rem] sm:right-[55%] md:right-[45%] md:w-[23rem] lg:w-[25rem] 2xl:w-[30rem]'
//         >
//           <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/2CJwuqE8pZnHPf5aMzORHp/5f41505e7b6334cefe04c5b3af9a560b/dtile2.svg' />
//         </div>

//         <div
//           ref={(el) => addDataTiles(el)}
//           className='absolute bottom-[12%] right-[15%] hidden w-[8rem] sm:w-[10rem] md:right-[8%] md:block md:w-[20rem] lg:w-[25rem] 2xl:w-[30rem]'
//         >
//           <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/7oZSQk4zUkmFPNoHYcXP0f/bd11448fcd31b1bb391de221bf50ac20/dtile1.svg' />
//         </div>
//         <div
//           ref={(el) => addDataTiles(el)}
//           className='absolute right-[40%] top-[10%] hidden w-[8rem] sm:w-[10rem] md:right-[50%] md:block md:w-[20rem] lg:w-[25rem] 2xl:w-[30rem]'
//         >
//           <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/oPkmfKjVdrKAQIC9Lib3y/4d72c6c0db459396903e8f18ae37ffc8/dtile3.svg' />
//         </div>
//         <div
//           ref={(el) => addDataTiles(el)}
//           className='absolute bottom-[50%] right-[38%] w-[19rem] sm:right-[55%] md:bottom-[60%] md:right-[3%] md:w-[23rem] lg:w-[25rem] 2xl:w-[30rem]'
//         >
//           <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/6UajM4fShcTUqz8iiSAh1A/7cb0d236c2b9e2899b59d46435122702/dtile4.svg' />
//         </div>
//       </NextImage>
//     </Container>
//   );
// }
