import React from 'react';

import { CommonPropTypes } from '../IconTypes';

const Close: React.FC<CommonPropTypes> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 -960 960 960'
      width='24px'
      fill='#e8eaed'
    >
      <path
        fill='currentColor'
        d='M256-213.85 213.85-256l224-224-224-224L256-746.15l224 224 224-224L746.15-704l-224 224 224 224L704-213.85l-224-224-224 224Z'
      />
    </svg>
  );
};

export default Close;
