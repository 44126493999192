'use client';

import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';

import styles from './HotSpots.module.css';

import useTileFadeIn from '@src/hooks/useTileFadeIn';

export default function Hotspots({
  attr,
  imageSrc,
  imageSeocndarySrc,
  alt,
  sectionClass,
  boxClassName,
  imageClassName,
}: any) {
  const { figureRef, addDataTiles } = useTileFadeIn();

  return (
    <Container attr={attr} className={`h-[58rem] lg:h-[auto] ${sectionClass}`}>
      <NextImage
        imgRef={figureRef}
        className='block h-[58rem] w-[auto] rounded-[2rem] md:hidden'
        src={imageSeocndarySrc}
        alt={alt}
      >
        <div
          ref={(el) => addDataTiles(el)}
          className={`${styles?.modalInput} left-[40%] top-[4%] block w-[18rem] md:grid md:h-auto lg:left-[67%] 3xl:w-[40rem]`}
        >
          <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
            <div className='mb-[1.8rem] flex'>
              <div>
                <NextImage
                  src='https://images.ctfassets.net/98s79sqwuajy/3aNhu32PhcKJwBGCTZVoPf/4ee721853773ca6fcc49179274bf1f82/hs-tile3.svg'
                  alt='icon'
                ></NextImage>
              </div>
            </div>
          </div>
        </div>
        <div className='absolute left-[5%] top-[87%] text-[2.6rem] font-[400] leading-[3rem] text-[#fff] lg:left-[58%] lg:top-[69%] lg:text-[4rem] lg:leading-[6rem] 3xl:text-[4.6rem]'>
          All your movement health <br />
          data in one place.
        </div>
      </NextImage>
      <NextImage
        imgRef={figureRef}
        className={`relative hidden h-[100%] w-[100%] rounded-[1rem] md:block md:h-[58rem] md:px-[0] lg:h-auto ${imageClassName}`}
        src={imageSrc}
        alt={alt}
      >
        <div
          ref={(el) => addDataTiles(el)}
          className={`${styles?.modalInput} left-[19%] top-[8%] block w-[35rem] md:left-[40%] md:grid md:h-auto lg:left-[60%] lg:w-[30rem] 2xl:left-[67%] 2xl:w-[35rem] 3xl:w-[40rem]`}
        >
          <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
            <div className='mb-[1.8rem] flex'>
              <div>
                <NextImage
                  src='https://images.ctfassets.net/98s79sqwuajy/3aNhu32PhcKJwBGCTZVoPf/4ee721853773ca6fcc49179274bf1f82/hs-tile3.svg'
                  alt='icon'
                ></NextImage>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={(el) => addDataTiles(el)}
          className={`${styles?.modalInput} top-[35%] hidden md:h-auto lg:left-[50%] lg:top-[40%] lg:grid lg:w-[30rem] 2xl:w-[35rem] 3xl:w-[40rem]`}
        >
          <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
            <div className='mb-[1.8rem] flex'>
              <div>
                <NextImage
                  src='https://images.ctfassets.net/98s79sqwuajy/2eymNUO2ShJb8S6Tn6CVkL/33ba39e15b16471da37f22d46c59ff03/hs-tile2.svg'
                  alt='icon'
                ></NextImage>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={(el) => addDataTiles(el)}
          className={`${styles?.modalInput} left-[20%] top-[70%] hidden w-[25rem] md:left-[60%] md:top-[60%] md:grid md:h-auto lg:left-[5%] lg:block 2xl:left-[20%] 3xl:w-[30rem]`}
        >
          <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
            <div className='mb-[1.8rem] flex'>
              <div>
                <NextImage
                  src='https://images.ctfassets.net/98s79sqwuajy/YPyo5EzwUaQeCIIPUvENJ/262a5edaee520a6511edca94c667513c/hs-tile1.svg'
                  alt='icon'
                ></NextImage>
              </div>
            </div>
          </div>
        </div>

        <div className='absolute left-[68%] top-[68%] hidden lg:top-[75%] lg:block lg:w-[20rem] xl:w-auto 2xl:left-[75%] 2xl:top-[75%]'>
          <NextImage
            src='https://images.ctfassets.net/98s79sqwuajy/2CMmjmiUWEAFq9RTvgdnx2/d71d1dfa6faaa89a7d31b842e48c3184/personal-best-18-kg.svg'
            alt='icon'
          ></NextImage>
        </div>

        <div className='absolute left-[5%] top-[81%] text-[2.6rem] font-[400] leading-[3rem] text-[#fff] lg:left-[58%] lg:top-[80%] lg:text-[3rem] lg:leading-[3.8rem] 2xl:left-[60%] 2xl:top-[80%] 2xl:text-[4rem] 2xl:leading-[6rem] 3xl:text-[4.6rem]'>
          All your movement health <br />
          data in one place.
        </div>
      </NextImage>
    </Container>
  );
}
