'use client';
import React from 'react';

import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';

import NextImage from '@src/components/Shared/NextImage';

import { MoveHealthAppPropTypes } from './Hero.types';
import useAnimateMoveHealthApp from './useAnimateMoveHealthApp';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import ArrowSlim from '@src/components/Icons/ArrowSlim';
import Close from '@src/components/Icons/Close';

const MoveHealthApp: React.FC<MoveHealthAppPropTypes> = ({ data }) => {
  const { figureRef, boxRef } = useAnimateMoveHealthApp();

  return (
    <Container
      className={`flex-col gap-[4.2rem] overflow-y-hidden lg:mt-[-20rem] lg:flex lg:flex-row lg:items-center lg:gap-[2rem] 2xl:mt-[-30rem] 2xl:gap-[6.4rem] 3xl:mt-[-35rem]`}
    >
      <NextImage
        imgRef={figureRef}
        className={`mb-[2.4rem] opacity-[0] lg:mb-[unset] lg:w-[50%]`}
        src={data?.mediaPrimary?.url}
        alt={data?.mediaPrimary?.description}
        aspectRatio={data?.mediaPrimary?.width / data?.mediaPrimary?.height}
        width={data?.mediaPrimary?.width}
        objectFit='contain'
      >
        <div
          ref={boxRef}
          className='absolute bottom-[20%] right-[5%] h-[15.931rem] w-[29.611rem] origin-bottom-right scale-[0.5] transform rounded-[1rem] bg-[rgba(41,64,84,0.9)] px-[1.4rem] py-[2.4rem] sm:scale-[0.75] md:scale-[1] lg:scale-[0.8] 2xl:scale-[1]'
        >
          <Close className='absolute right-[0.5rem] top-[0.5rem] w-[2rem] text-body-copy--light' />
          <p className='mb-[1rem] text-[1.8rem] font-[500] text-body-copy--light'>
            You have new tasks!
          </p>

          <p className='mb-[1.6rem] text-[1.3rem] text-body-copy--light'>
            Your dedication to your rehab program will contribute significantly to your successful
            recovery.
          </p>

          <button className='pointer-events-none flex cursor-default items-center justify-between rounded-[1rem] bg-primary px-[1rem] py-[0.5rem] text-[1.3rem] font-[400] text-body-copy--light'>
            <span>View tasks</span>
            <ArrowSlim className='ml-[0.5rem] w-[1rem] text-body-copy--light' />
          </button>
        </div>
      </NextImage>

      <div
        className={`lg:relative lg:z-[20] lg:mb-[-10rem] lg:w-[50%] lg:self-center 2xl:mb-[-25rem]`}
      >
        <SectionHeader
          className={`lg:relative lg:z-[5] lg:py-[4rem]`}
          preTitleClassName='mb-[1.6rem] font-[400]'
          title={data?.title}
          titleClassName={`text-[2.8rem] mb-[1.8rem] lg:mb-[3.2rem] lg:text-[5.4rem] 2xl:text-[6.1rem] lg:leading-[5.2rem] lg:tracking-[-0.096rem]`}
          childrenClassName='relative'
          align='left'
        >
          <ParagraphSeparator>
            <RenderBodyCopy className='max-w-[47rem]' bodyCopy={data?.bodyCopy?.json?.content} />
          </ParagraphSeparator>
          <RenderButtons items={data?.buttonCollection?.items} />
        </SectionHeader>
      </div>
    </Container>
  );
};

export default MoveHealthApp;
