import React from 'react';
import useGsapLoaded from '@src/hooks/useGsapLoaded';

import imagesLoaded from 'imagesloaded';

export const useAnimateHero = () => {
  const isGsapLoaded = useGsapLoaded([]);

  const figureRef: React.RefObject<HTMLElement> = React.useRef(null);
  const containerRef: React.RefObject<HTMLElement> = React.useRef(null);
  const tileRefs = React.useRef<(HTMLElement | null)[]>([]);

  const addDataTiles = (el: any) => {
    if (el && !tileRefs.current.includes(el)) {
      tileRefs.current.push(el);
    }
  };

  React.useEffect(() => {
    if (isGsapLoaded) {
      imagesLoaded(document.querySelectorAll('img'), () => {
        const tl = window.gsap.timeline();

        const imgElement = figureRef.current?.querySelector('img');

        // Initialize tile animations similarly to useTileFadeIn
        tileRefs.current.forEach((el, index) => {
          if (el) {
            window.gsap.fromTo(
              el,
              {
                x: '100px',
                opacity: 0,
              },
              {
                x: '0px',
                opacity: 1,
                duration: 1.2,
                delay: index * 0.2, // Adds a delay based on the index
                ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
              }
            );
          }
        });

        tl.fromTo(
          imgElement,
          {
            scale: 1.05,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          }
        ).fromTo(
          containerRef?.current,
          { x: -50, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          },
          0
        );
      });
    }
  }, [isGsapLoaded]);

  return { figureRef, addDataTiles, containerRef };
};
