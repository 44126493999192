import React from 'react';

import { ParagraphBreakPropTypes } from './ParagraphSeparator.types';

const ParagraphBreak: React.FC<ParagraphBreakPropTypes> = ({
  className = '',
  style = {},
  children,
}) => {
  return (
    <div
      style={style}
      className={`[&>*:not(:last-child)]:mb-[1.6rem] [&>*:not(:last-child)]:lg:mb-[4.6rem] ${className}`}
    >
      {children}
    </div>
  );
};

export default ParagraphBreak;
