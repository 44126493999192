'use client';

import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';
import InlineSVG from '@src/components/Shared/InlineSVG';

import useTileFadeIn from '@src/hooks/useTileFadeIn';

export default function DataTiles({ attr, imageSrc, alt, sectionClass, imageClassName }: any) {
  const { figureRef, addDataTiles } = useTileFadeIn();

  return (
    <Container attr={attr} className={`h-[58rem] lg:h-[auto] ${sectionClass}`}>
      <NextImage
        imgRef={figureRef}
        className={`relative h-[100%] w-[100%] rounded-[1rem] md:h-[58rem] md:px-[0] lg:h-auto [&>img]:object-[71%,53%] ${imageClassName}`}
        src={imageSrc}
        alt={alt}
      >
        <div className='relative left-[5%] top-[8%] text-[2.3rem] font-[300] leading-[3.6rem] text-[#fff] md:top-[42%] md:text-[3.6rem] md:leading-[4.2rem] lg:text-[2.4rem] lg:leading-[3.2rem] xl:text-[4.8rem] xl:leading-[6rem]'>
          All of your movement health <br />
          data in <strong className='font-[500]'>one place.</strong>
        </div>

        <div
          ref={(el) => addDataTiles(el)}
          className='absolute bottom-[5%] right-[32%] w-[19rem] sm:right-[55%] md:right-[45%] md:w-[23rem] lg:w-[25rem] 2xl:w-[30rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/6hDlyn2e0Fm5fpIlDjdNzB/ab8c6f69353f2d8a11b9e04229dad14f/squat-timer.svg' />
        </div>

        <div
          ref={(el) => addDataTiles(el)}
          className='absolute bottom-[12%] right-[46%] hidden w-[8rem] sm:w-[10rem] md:right-[8%] md:block md:w-[20rem] lg:w-[25rem] 2xl:w-[30rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/3kHxKQixiwmehdR1rYInXt/aaae62f78f366673ab9620208d0c2514/squat.svg' />
        </div>
        <div
          ref={(el) => addDataTiles(el)}
          className='absolute right-[40%] top-[10%] hidden w-[8rem] sm:w-[10rem] md:right-[50%] md:block md:w-[20rem] lg:w-[25rem] 2xl:w-[30rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/21XoqG3p18bzoSXh5fFMg8/fa2d8d807e027151c4a5492abcdaa0a0/assigned-program.svg' />
        </div>
        <div
          ref={(el) => addDataTiles(el)}
          className='absolute bottom-[50%] right-[35%] w-[19rem] sm:right-[55%] md:bottom-[60%] md:right-[3%] md:w-[23rem] lg:w-[25rem] 2xl:w-[30rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/13Bq83gJetKjPODqBZkQuz/beb4a0bae9ac1ebc64007691df5f8acc/squat-peak-concentric-force.svg' />
        </div>
      </NextImage>
    </Container>
  );
}
