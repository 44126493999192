import React from 'react';

import useGsapLoaded from '@src/hooks/useGsapLoaded';

import imagesLoaded from 'imagesloaded';

const useAnimateMoveHealthApp = () => {
  const isGsapLoaded = useGsapLoaded([]);

  const figureRef: React.RefObject<HTMLElement> = React.useRef(null);
  const boxRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useEffect(() => {
    if (isGsapLoaded) {
      imagesLoaded(document.querySelectorAll('img'), () => {
        const tl = window.gsap.timeline();

        tl.fromTo(
          figureRef.current,
          { y: 60 },
          {
            delay: 0.6,
            opacity: 1,
            y: 0,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          }
        );

        window.gsap.fromTo(
          boxRef.current,
          { x: 100, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 1,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
            scrollTrigger: {
              trigger: figureRef.current,
              start: '-40%',
              toggleActions: 'play none none reverse',
            },
          }
        );
      });
    }
  }, [isGsapLoaded]);

  return { figureRef, boxRef };
};

export default useAnimateMoveHealthApp;
