'use client';
import React from 'react';

import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import RenderButtons from '@src/components/Shared/RenderButtons';

import { HeroPropTypes } from './Hero.types';
import NextImage from '@src/components/Shared/NextImage';
import InlineSVG from '@src/components/Shared/InlineSVG';
import { useAnimateHero } from './useAnimateHero';

import styles from './HotSpots.module.css';

const Hero: React.FC<HeroPropTypes> = ({ data }) => {
  const { figureRef, addDataTiles, containerRef } = useAnimateHero();

  return (
    <div data-animation='home-page-hero' className='relative'>
      <NextImage
        imgRef={figureRef}
        id={data?.scrollTo?.slice(1)}
        src={data?.heroImage?.url}
        alt='A portrait'
        className='[&>img]:object-[62% 50%] mb-[4rem] h-[85vh] md:mb-[6rem] lg:mb-[8rem] [&>img]:opacity-[0] lg:[&>img]:object-[40%,85%] 2xl:[&>img]:object-[20%,85%]'
      >
        <Container
          containerRef={containerRef}
          className='!mb-[0] mt-[12rem] flex h-[100%] flex-col md:mt-[unset] md:justify-center 2xl:!bottom-[5rem]'
        >
          <InlineSVG
            className={`mb-[2.4rem] mt-[-5.87rem] max-w-[30rem] md:max-w-[44rem] lg:max-w-[44rem] 2xl:max-w-[64rem]`}
            src={data?.heroLogo?.url}
          />

          <SectionHeader
            title={data?.title}
            titleClassName='max-w-[30rem] lg:max-w-[40rem] text-[2.4rem] leading-[2.7rem] tracking-[-0.0342rem] md:text-[3rem] md:max-w-[35rem] text-body-copy--light md:text-[2rem] md:leading-[3rem] md:tracking-[-0.036rem] lg:text-[3.4rem] lg:max-w-[50rem] lg:leading-[3.6rem]  2xl:text-[4.45rem] 2xl:leading-[5rem] !font-[300]'
            childrenClassName='relative'
            titleHighlightBold
          >
            <RenderButtons items={data?.buttonCollection?.items} />
          </SectionHeader>
        </Container>

        <div
          ref={(el) => addDataTiles(el)}
          className='absolute right-[58%] top-[35%] z-[5] w-[17rem] opacity-0 md:right-[-6%] md:top-[2%] md:block md:w-[21rem] lg:w-[25rem] 2xl:w-[25rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/8k6TRZ8oYSQKXVjSRR6Gf/313088eb08c8b70fb35e551dd3780081/quiet-stand.svg' />
        </div>
        <div
          ref={(el) => addDataTiles(el)}
          className='absolute right-[51%] top-[50%] z-[5] w-[17rem] opacity-0 md:right-[8%] md:top-[15%] md:block md:w-[21rem] lg:w-[25rem] 2xl:w-[25rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/3iiw3wrQg4n86S8B2RXNDM/e99dec9d6424e88fa058e8b60057101a/plank-timer.svg' />
        </div>

        <div
          ref={(el) => addDataTiles(el)}
          className='absolute right-[55%] top-[75%] z-[5] w-[17rem] opacity-0 md:right-[-5%] md:top-[40%] md:block md:w-[21rem] lg:w-[25rem] 2xl:w-[25rem]'
        >
          <InlineSVG src='https://images.ctfassets.net/98s79sqwuajy/7sPISGfabKp2l9JXI6afww/30bd1a30f7027cc96cbbac45f23c4dbe/knee-injury.svg' />
        </div>

        <div
          ref={(el) => addDataTiles(el)}
          className={`absolute right-[70%] top-[52%] z-[0] grid w-[13rem] !bg-none md:right-[-1%] md:top-[18%] md:h-auto md:w-[21rem]`}
        >
          <div className={`px-[1rem]`}>
            <div className='mb-[1rem]'>
              <div className={`mb-[-0.5rem] pt-[0.5rem] text-left`}>
                <NextImage
                  src='https://images.ctfassets.net/98s79sqwuajy/1wqWGHaM7axnh84F6HXY3o/4cec66adb7b83c072760d34e6df8e460/arrow-circle.svg'
                  alt='icon'
                  className={`mr-[1.6rem] w-[100%] ${styles?.arrowSpin}`}
                ></NextImage>
              </div>
            </div>
          </div>
        </div>
      </NextImage>
    </div>
  );
};

export default Hero;
