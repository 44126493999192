import React from 'react';
import useGsapLoaded from '@src/hooks/useGsapLoaded';

import imagesLoaded from 'imagesloaded';

const useTileFadeIn = () => {
  const isGsapLoaded = useGsapLoaded(['ScrollTrigger']);
  const figureRef: React.RefObject<HTMLElement> = React.useRef(null);
  const tileRefs = React.useRef<(HTMLElement | null)[]>([]);

  const addDataTiles = (el: any) => {
    if (el && !tileRefs.current.includes(el)) {
      tileRefs.current.push(el);
    }
  };

  React.useEffect(() => {
    if (isGsapLoaded) {
      imagesLoaded(document.querySelectorAll('img'), () => {
        tileRefs.current.forEach((el) => {
          if (el) {
            window.gsap.fromTo(
              el,
              {
                x: '100px',
                opacity: 0,
              },
              {
                x: '0px',
                opacity: 0.99,
                duration: 1.2,
                ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
                scrollTrigger: {
                  trigger: el,
                  start: 'top 80%',
                  end: 'bottom 20%',
                  toggleActions: 'play none none reverse',
                },
              }
            );
          }
        });
      });
    }
  }, [isGsapLoaded]);

  return { figureRef, addDataTiles };
};

export default useTileFadeIn;
