import React from 'react';
import useGsapLoaded from '@src/hooks/useGsapLoaded';

import imagesLoaded from 'imagesloaded';

const useAnimateGridTile = () => {
  const isGsapLoaded = useGsapLoaded([]);
  const containerRef = React.useRef(null);
  const elementRefs = {
    oneRef: React.useRef(null),
    twoRef: React.useRef(null),
    threeRef: React.useRef(null),
    fourRef: React.useRef(null),
    fiveRef: React.useRef(null),
    sixRef: React.useRef(null),
    sevenRef: React.useRef(null),
    eightRef: React.useRef(null),
    nineRef: React.useRef(null),
    tenRef: React.useRef(null),
    elevenRef: React.useRef(null),
    twelveRef: React.useRef(null),
    thirteenRef: React.useRef(null),
    fourteenRef: React.useRef(null),
  };

  React.useEffect(() => {
    if (isGsapLoaded) {
      imagesLoaded(document.querySelectorAll('img'), () => {
        // Create a GSAP timeline
        const timeline = window.gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            start: '-0% 32%',
            end: '-0% 32%',
            toggleActions: 'play none none reverse',
          },
        });

        timeline.fromTo(
          elementRefs.oneRef.current,
          { opacity: 0, y: 50 },
          {
            opacity: 0.99,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
            y: 0,
          }
        );

        timeline?.fromTo(
          [
            elementRefs.twoRef.current,
            elementRefs.fourRef.current,
            elementRefs.sixRef.current,
            elementRefs.eightRef.current,
            elementRefs.threeRef.current,
          ],
          { opacity: 0, y: 50 },
          {
            opacity: 0.99,
            y: 0,
            stagger: 0.1,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          },
          'start'
        );

        timeline.fromTo(
          elementRefs.twelveRef.current,
          { opacity: 0, y: 50 },
          {
            opacity: 0.99,
            y: 0,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          },
          'start+=0.5'
        );

        timeline.fromTo(
          elementRefs.fourteenRef.current,
          { opacity: 0, y: 50 },
          {
            opacity: 0.99,
            y: 0,
            stagger: 0.1,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          },
          'start'
        );

        timeline.fromTo(
          [
            elementRefs.fiveRef.current,
            elementRefs.sevenRef.current,
            elementRefs.nineRef.current,
            elementRefs.tenRef.current,
            elementRefs.elevenRef.current,
            elementRefs.thirteenRef.current,
          ].filter(Boolean),
          { opacity: 0, y: 50 },
          {
            opacity: 0.99,
            y: 0,
            stagger: 0.1,
            duration: 1.2,
            ease: 'cubic-bezier(0.88, -0.23, 0.29, 1)',
          },
          'start+=.4'
        );
      });
    }
  }, [isGsapLoaded]);

  return {
    containerRef,
    ...elementRefs,
  };
};

export default useAnimateGridTile;
